import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";

import React, {
  useState,
  useEffect,
  useContext,
  Component,
  Suspense,
  lazy,
} from "react";

import {
  CPHandlerProvider,
  CPHandlerContext,
} from "./context/CPHandlerProvider";

const { GoogleLogout } = lazy(() => import("react-google-login"));

const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ChatDash = lazy(() => import("./pages/chat/ChatScreen"));
const ChatsListPopup = lazy(() => import("./pages/chat/ChatsListPopup"));
const ChatMessageView = lazy(() => import("./pages/chat/ChatMessageView"));
const ChatBlockedIPs = lazy(() => import("./pages/chat/ChatBlockedIPs"));

const ChatDomainList = lazy(() => import("./pages/chat_domain/List"));
const ChatDomainAdd = lazy(() => import("./pages/chat_domain/Add"));
const ChatDomainEdit = lazy(() => import("./pages/chat_domain/Edit"));

const NLTK = lazy(() => import("./pages/NLTK"));

const RecordList = lazy(() => import("./pages/records/List"));

const LeadBoard = lazy(() => import("./pages/leads/Board"));
const LeadAddNew = lazy(() => import("./pages/leads/LeadAddNew"));
const LeadsList = lazy(() => import("./pages/leads/LeadsList"));
const LeadView = lazy(() => import("./pages/leads/LeadView"));
const LeadEdit = lazy(() => import("./pages/leads/LeadEdit"));
const LeadEditTest = lazy(() => import("./pages/leads/LeadEditTest"));
const LeadCRM = lazy(() => import("./pages/leads/LeadCRM"));
const LeadIncoming = lazy(() => import("./pages/leads/LeadIncoming"));
const LeadClientDash = lazy(() => import("./pages/leads/LeadClientDash"));
const LeadCatsList = lazy(() => import("./pages/lead_cats/List"));
const LeadCatsAdd = lazy(() => import("./pages/lead_cats/Add"));
const LeadCatsEdit = lazy(() => import("./pages/lead_cats/Edit"));
const LeadCalls = lazy(() => import("./pages/leads/LeadCalls"));
const LeadSms = lazy(() => import("./pages/leadsms/List"));
const LeadAssignedUser = lazy(() => import("./pages/leads/LeadAssignedUser"));
const LeadSetting = lazy(() => import("./pages/leads/LeadSetting"));

const LeadContactList = lazy(() => import("./pages/leadcontacts/List"));

const LeadMails = lazy(() => import("./pages/leadmails/List"));

const LeadTypeList = lazy(() => import("./pages/leadtype/List"));
const LeadEmailGroupList = lazy(() => import("./pages/leademailgroup/List"));

const LeadStatusList = lazy(() => import("./pages/leadstatus/List"));
const LeadStatusAdd = lazy(() => import("./pages/leadstatus/Add"));
const LeadStatusEdit = lazy(() => import("./pages/leadstatus/Edit"));

const TwilioIncoming = lazy(() => import("./pages/caller/Incoming"));
const TwilioToken = lazy(() => import("./pages/caller/Token"));
const TwilioOutgoing = lazy(() => import("./pages/caller/Outgoing"));

const HtmlBlocksList = lazy(() => import("./pages/html_blocks/List"));
const HtmlBlocksAdd = lazy(() => import("./pages/html_blocks/Add"));
const HtmlBlocksEdit = lazy(() => import("./pages/html_blocks/Edit"));
const HtmlBlocksSetList = lazy(() => import("./pages/html_blocks_set/List"));
const HtmlBlocksSetAdd = lazy(() => import("./pages/html_blocks_set/Add"));
const HtmlBlocksSetEdit = lazy(() => import("./pages/html_blocks_set/Edit"));

const BenefitList = lazy(() => import("./pages/benefit/List"));
const BenefitAdd = lazy(() => import("./pages/benefit/Add"));
const BenefitEdit = lazy(() => import("./pages/benefit/Edit"));
const BenefitSetList = lazy(() => import("./pages/benefit_set/List"));
const BenefitSetAdd = lazy(() => import("./pages/benefit_set/Add"));
const BenefitSetEdit = lazy(() => import("./pages/benefit_set/Edit"));

const TestimonialList = lazy(() => import("./pages/testimonial/List"));
const TestimonialAdd = lazy(() => import("./pages/testimonial/Add"));
const TestimonialEdit = lazy(() => import("./pages/testimonial/Edit"));
const TestimonialSetList = lazy(() => import("./pages/testimonial_set/List"));
const TestimonialSetAdd = lazy(() => import("./pages/testimonial_set/Add"));
const TestimonialSetEdit = lazy(() => import("./pages/testimonial_set/Edit"));

const ObjectionList = lazy(() => import("./pages/objection/List"));
const ObjectionAdd = lazy(() => import("./pages/objection/Add"));
const ObjectionEdit = lazy(() => import("./pages/objection/Edit"));
const ObjectionSetList = lazy(() => import("./pages/objection_set/List"));
const ObjectionSetAdd = lazy(() => import("./pages/objection_set/Add"));
const ObjectionSetEdit = lazy(() => import("./pages/objection_set/Edit"));

const LPBuilder = lazy(() => import("./pages/lp_builder/LPBuilder"));

const FaqList = lazy(() => import("./pages/faq/List"));
const FaqAdd = lazy(() => import("./pages/faq/Add"));
const FaqEdit = lazy(() => import("./pages/faq/Edit"));
const FaqCatsList = lazy(() => import("./pages/faq_cats/List"));
const FaqCatsAdd = lazy(() => import("./pages/faq_cats/Add"));
const FaqCatsEdit = lazy(() => import("./pages/faq_cats/Edit"));
const FaqTagsList = lazy(() => import("./pages/faq_tags/List"));
const FaqTagsAdd = lazy(() => import("./pages/faq_tags/Add"));
const FaqTagsEdit = lazy(() => import("./pages/faq_tags/Edit"));
const FaqSynonymsList = lazy(() => import("./pages/faq_synonyms/List"));
const FaqSynonymsAdd = lazy(() => import("./pages/faq_synonyms/Add"));
const FaqSynonymsEdit = lazy(() => import("./pages/faq_synonyms/Edit"));
const FaqQuesList = lazy(() => import("./pages/faq_ques/List"));
const FaqQuesAdd = lazy(() => import("./pages/faq_ques/Add"));
const FaqQuesEdit = lazy(() => import("./pages/faq_ques/Edit"));
const FaqAnsList = lazy(() => import("./pages/faq_ans/List"));
const FaqAnsAdd = lazy(() => import("./pages/faq_ans/Add"));
const FaqAnsEdit = lazy(() => import("./pages/faq_ans/Edit"));

const FaqChattrainingList = lazy(() =>
  import("./pages/faq_chat_trainings/List")
);
const FaqChattrainingAdd = lazy(() => import("./pages/faq_chat_trainings/Add"));
const FaqChattrainingEdit = lazy(() =>
  import("./pages/faq_chat_trainings/Edit")
);

const SnippetsList = lazy(() => import("./pages/faq_snippets/List"));
const SnippetsAdd = lazy(() => import("./pages/faq_snippets/Add"));
const SnippetsEdit = lazy(() => import("./pages/faq_snippets/Edit"));

const ChatPicAdd = lazy(() => import("./pages/faq_chat_pic/Add"));

const NcmForm = lazy(() => import("./pages/ncm_form/Add"));
const GmailApi = lazy(() => import("./pages/gmail_api/Add"));
const GoogleToken = lazy(() => import("./pages/gmail_api/Token"));

const FaqSuggestedList = lazy(() => import("./pages/faq_suggested/List"));
const FaqSuggestedAdd = lazy(() => import("./pages/faq_suggested/Add"));
const FaqSuggestedEdit = lazy(() => import("./pages/faq_suggested/Edit"));

const FaqSuggestedAdd_v2 = lazy(() => import("./pages/faq_suggested_v2/Add"));
const FaqSuggestedEdit_v2 = lazy(() => import("./pages/faq_suggested_v2/Edit"));

const PostList = lazy(() => import("./pages/post/List"));
const PostAdd = lazy(() => import("./pages/post/Add"));
const PostEdit = lazy(() => import("./pages/post/Edit"));
const PostCatList = lazy(() => import("./pages/post_cat/List"));
const PostCatAdd = lazy(() => import("./pages/post_cat/Add"));
const PostCatEdit = lazy(() => import("./pages/post_cat/Edit"));

const PostSettingList = lazy(() => import("./pages/post_settings/List"));
const PostUpdate = lazy(() => import("./pages/post_settings/Add"));
const PostByname = lazy(() => import("./pages/post_settings/Edit"));

const PagesList = lazy(() => import("./pages/pages/List"));
const WebsitesList = lazy(() => import("./pages/websites/List"));
const SiteList = lazy(() => import("./pages/sites/List"));
const PagesAdd = lazy(() => import("./pages/pages/Add"));
const PagesEdit = lazy(() => import("./pages/pages/Edit"));

const MessageList = lazy(() => import("./pages/message/List"));
const MessageAdd = lazy(() => import("./pages/message/Add"));
const MessageEdit = lazy(() => import("./pages/message/Edit"));

const QuotesList = lazy(() => import("./pages/quotes/List"));
const QuotesAdd = lazy(() => import("./pages/quotes/Add"));
const QuotesEdit = lazy(() => import("./pages/quotes/Edit"));

const ShowcasesList = lazy(() => import("./pages/showcases/List"));
const ShowcasesAdd = lazy(() => import("./pages/showcases/Add"));
const ShowcasesEdit = lazy(() => import("./pages/showcases/Edit"));

const VideosList = lazy(() => import("./pages/videos/List"));
const VideosAdd = lazy(() => import("./pages/videos/Add"));
const VideosEdit = lazy(() => import("./pages/videos/Edit"));

const ContactTextList = lazy(() => import("./pages/contact_text/List"));
const ContactTextAdd = lazy(() => import("./pages/contact_text/Add"));
const ContactTextEdit = lazy(() => import("./pages/contact_text/Edit"));

const VirtualToursList = lazy(() => import("./pages/virtual_tours/List"));
const VirtualToursAdd = lazy(() => import("./pages/virtual_tours/Add"));
const VirtualToursEdit = lazy(() => import("./pages/virtual_tours/Edit"));

const OffersFormList = lazy(() => import("./pages/offers_form/List"));
const OffersFormAdd = lazy(() => import("./pages/offers_form/Add"));
const OffersFormEdit = lazy(() => import("./pages/offers_form/Edit"));

const CustomFormList = lazy(() => import("./pages/custom_form/List"));
const CustomFormAdd = lazy(() => import("./pages/custom_form/Add"));
const CustomFormEdit = lazy(() => import("./pages/custom_form/Edit"));

const FormBuilderList = lazy(() => import("./pages/form_builder/List"));

const OffersGroupsList = lazy(() => import("./pages/offer_groups/List"));
const OffersGroupsAdd = lazy(() => import("./pages/offer_groups/Add"));
const OffersGroupsEdit = lazy(() => import("./pages/offer_groups/Edit"));

const EmailTempsList = lazy(() => import("./pages/email_temps/List"));
const EmailTempsAdd = lazy(() => import("./pages/email_temps/Add"));
const EmailTempsEdit = lazy(() => import("./pages/email_temps/Edit"));

const EmailGroupsList = lazy(() => import("./pages/email_groups/List"));
const EmailGroupsAdd = lazy(() => import("./pages/email_groups/Add"));
const EmailGroupsEdit = lazy(() => import("./pages/email_groups/Edit"));

const EmailFilteringList = lazy(() => import("./pages/email_filters/List"));

const WidgetImageSettingList = lazy(() => import("./pages/widget_image_setting/List"));

const HeroesList = lazy(() => import("./pages/heroes/List"));
const HeroesAdd = lazy(() => import("./pages/heroes/Add"));
const HeroesEdit = lazy(() => import("./pages/heroes/Edit"));



const ClientGalleryImagesList = lazy(() =>
  import("./pages/clientgalleryimages/List")
);
const ClientGalleryImagesAdd = lazy(() =>
  import("./pages/clientgalleryimages/Add")
);
const ClientGalleryImagesEdit = lazy(() =>
  import("./pages/clientgalleryimages/Edit")
);

const ClientStoryBoardsList = lazy(() =>
  import("./pages/clientstoryboards/List")
);
const ClientStoryBoardsAdd = lazy(() =>
  import("./pages/clientstoryboards/Add")
);
const ClientStoryBoardsEdit = lazy(() =>
  import("./pages/clientstoryboards/Edit")
);

const FollowUpEmailTempsAdd = lazy(() =>
  import("./pages/followup_email_temps/Add")
);
const FollowUpEmailTempsEdit = lazy(() =>
  import("./pages/followup_email_temps/Edit")
);

const FollowUpEmailGroupsAdd = lazy(() =>
  import("./pages/followup_email_groups/Add")
);
const FollowUpEmailGroupsEdit = lazy(() =>
  import("./pages/followup_email_groups/Edit")
);

const MediaList = lazy(() => import("./pages/media/List"));
const MediaAdd = lazy(() => import("./pages/media/Add"));
const MediaEdit = lazy(() => import("./pages/media/Edit"));

const ClientsList = lazy(() => import("./pages/clients/List"));
const ClientsAdd = lazy(() => import("./pages/clients/Add"));
const ClientsEdit = lazy(() => import("./pages/clients/Edit"));

const GroupsAdd = lazy(() => import("./pages/groups/Add"));
const GroupsEdit = lazy(() => import("./pages/groups/Edit"));

const UsersList = lazy(() => import("./pages/users/List"));
const UsersAdd = lazy(() => import("./pages/users/Add"));
const UsersEdit = lazy(() => import("./pages/users/Edit"));

const RolesAdd = lazy(() => import("./pages/roles/Add"));
const RolesEdit = lazy(() => import("./pages/roles/Edit"));

const PermissionsAdd = lazy(() => import("./pages/permissions/Add"));
const PermissionsEdit = lazy(() => import("./pages/permissions/Edit"));

const LpsessionAdd = lazy(() => import("./pages/lpsessions/Add"));
const LpsessionEdit = lazy(() => import("./pages/lpsessions/Edit"));

const TemplatesList = lazy(() => import("./pages/templates/List"));
const TemplatesAdd = lazy(() => import("./pages/templates/Add"));
const TemplatesEdit = lazy(() => import("./pages/templates/Edit"));
const Kanban = lazy(() => import("./pages/templates/Kanban"));

const ThemesList = lazy(() => import("./pages/themes/List"));

const LandingPageList = lazy(() => import("./pages/landing_page/List"));
const LandingPageAdd = lazy(() => import("./pages/landing_page/Add"));
const LandingPageEdit = lazy(() => import("./pages/landing_page/Edit"));

const VarTextsAdd = lazy(() => import("./pages/landing_page/vartexts/Add"));
const VarTextsEdit = lazy(() => import("./pages/landing_page/vartexts/Edit"));

const AssignHeroes = lazy(() => import("./pages/lp_assigns/heroes"));
const AssignOffers = lazy(() => import("./pages/lp_assigns/offers"));
const AssignVideos = lazy(() => import("./pages/lp_assigns/videos"));
const AssignQuotes = lazy(() => import("./pages/lp_assigns/quotes"));
const AssignClientVars = lazy(() => import("./pages/lp_assigns/clientvars"));
const AssignVarSets = lazy(() => import("./pages/lp_assigns/varsets"));
const AssignHtmlBlocks = lazy(() => import("./pages/lp_assigns/html_blocks"));
const AssignHtmlBlockSets = lazy(() =>
  import("./pages/lp_assigns/htmlblock_sets")
);
const AssignVirtualTours = lazy(() =>
  import("./pages/lp_assigns/virtual_tours")
);
const AssignShowcases = lazy(() => import("./pages/lp_assigns/showcases"));
const AssignGallery = lazy(() => import("./pages/lp_assigns/gallery"));
const AssignStoryboard = lazy(() => import("./pages/lp_assigns/storyboard"));
const AssignHeadingImages = lazy(() =>
  import("./pages/lp_assigns/heading_images")
);
const AssignOfferGroups = lazy(() => import("./pages/lp_assigns/offer_groups"));

const TestAdd = lazy(() => import("./pages/test/Add"));
const TestEdit = lazy(() => import("./pages/test/Edit"));

const App = () => {
  const [urole, setURole] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [gauth, setGauth] = useState(false);

  const slugarr = window.location.pathname.substr(1).split("/");

  useEffect(() => {
    if (slugarr[0] === "gauth-callback") {
      setGauth(true);
    } else if (slugarr[0] === "lead_view") {
      setGauth(true);
    } else if (localStorage.getItem("userData") === null && slugarr[0] === "login") {
      setIsLogin(false);
    } else if (localStorage.getItem("userData") === null) {
      setIsLogin(false);
      if (slugarr[0] !== "forgot_password" && slugarr[0] !== "reset_password") {
        window.location.assign("/login");
      }
    } else {
      setIsLogin(true);
      const loginData = JSON.parse(localStorage.getItem("userData"));
      setURole(loginData.data.role);

      if (slugarr[0] === "forgot_password" || slugarr[0] === "reset_password" || slugarr[0] === "login") {
        window.location.assign("/");
      }
    }
  }, []);
  
  if (isLogin === true) {
    if (urole !== "Sales Manager") {
      return (
        <CPHandlerProvider>
          <BrowserRouter>
            <React.Suspense
              fallback={
                <div className="lazyLoader">
                  <span></span>
                </div>
              }
            >
              <Routes>
                <Route path="/chat-dash" element={<ChatDash />} />
                <Route path="/chats" element={<ChatsListPopup />} />
                <Route path="/chat_message" element={<ChatMessageView />} />
                <Route path="/chat_blocked_ips" element={<ChatBlockedIPs />} />
                <Route path="/nltk" element={<NLTK />} />

                <Route path="/chat_domain" element={<ChatDomainList />} />
                <Route path="/chat_domain_edit" element={<ChatDomainList />} />

                <Route exact path="/" element={<RecordList />} />
                <Route exact path="/dashboard" element={<RecordList />} />

                <Route path="/leads" element={<LeadsList />} />
                <Route path="/lead_view" element={<LeadView />} />
                <Route path="/lead_addnew" element={<LeadsList />} />
                <Route path="/lead_edit" element={<LeadsList />} />
                <Route path="/lead_call" element={<LeadCalls />} />
                <Route path="/lead_edit_test_sms" element={<LeadEditTest />} />
                <Route path="/lead_crm" element={<LeadCRM />} />
                <Route path="/incoming" element={<TwilioIncoming />} />
                <Route path="/token" element={<TwilioToken />} />
                <Route path="/voice" element={<TwilioOutgoing />} />
                <Route path="/lead_board" element={<LeadBoard />} />
                <Route path="/lead_client_dash" element={<LeadClientDash />} />
                <Route path="/lead_cats" element={<LeadCatsList />} />
                <Route path="/lead_cats_edit" element={<LeadCatsList />} />

                <Route path="/leadstatus" element={<LeadStatusList />} />
                <Route path="/leadstatus_edit" element={<LeadStatusList />} />

                <Route path="/leadtype" element={<LeadTypeList />} />
                <Route path="/leadtype_edit" element={<LeadTypeList />} />

                <Route path="/leadcontacts" element={<LeadContactList />} />
                <Route path="/leadcontact_edit" element={<LeadContactList />} />

                <Route path="/lead_sms" element={<LeadSms />} />
                <Route path="/lead_sms_edit" element={<LeadSms />} />

                <Route path="/leadmails" element={<LeadMails />} />
                <Route path="/leadmails_edit" element={<LeadMails />} />

                <Route
                  path="/leademailgroup"
                  element={<LeadEmailGroupList />}
                />
                <Route
                  path="/leademailgroup_edit"
                  element={<LeadEmailGroupList />}
                />

                <Route
                  path="/leadassigned_user"
                  element={<LeadAssignedUser />}
                />
                <Route
                  path="/leadassigned_user_edit"
                  element={<LeadAssignedUser />}
                />

                <Route path="/lead_setting" element={<LeadSetting />} />
                <Route path="/lead_setting_edit" element={<LeadSetting />} />

                <Route path="/clients" element={<ClientsList />} />
                <Route path="/client_edit" element={<ClientsList />} />

                <Route path="/lp_new" element={<LandingPageList />} />
                <Route path="/lp_edit" element={<LandingPageList />} />

                <Route path="/vartexts" element={<VarTextsAdd />} />
                <Route path="/vartext_edit" element={<VarTextsEdit />} />

                <Route path="/templates" element={<TemplatesList />} />
                <Route path="/template_edit" element={<TemplatesList />} />
                <Route path="/template_kanban" element={<Kanban />} />

                <Route path="/themes" element={<ThemesList />} />
                <Route path="/theme_edit" element={<ThemesList />} />

                <Route path="/groups" element={<GroupsAdd />} />
                <Route path="/groups_edit" element={<GroupsEdit />} />

                <Route path="/users" element={<UsersList />} />
                <Route path="/user_edit" element={<UsersList />} />

                <Route path="/roles" element={<RolesAdd />} />
                <Route path="/roles_edit" element={<RolesEdit />} />

                <Route path="/permissions" element={<PermissionsAdd />} />
                <Route path="/permissions_edit" element={<PermissionsEdit />} />

                <Route path="/html_blocks" element={<HtmlBlocksList />} />
                <Route path="/html_block_edit" element={<HtmlBlocksList />} />
                <Route
                  path="/html_block_sets"
                  element={<HtmlBlocksSetList />}
                />
                <Route
                  path="/html_block_set_edit"
                  element={<HtmlBlocksSetList />}
                />

                <Route path="/benefit" element={<BenefitList />} />
                <Route path="/benefit_edit" element={<BenefitList />} />
                <Route path="/benefit_set" element={<BenefitSetList />} />
                <Route path="/benefit_set_edit" element={<BenefitSetList />} />

                <Route path="/testimonial" element={<TestimonialList />} />
                <Route path="/testimonial_edit" element={<TestimonialList />} />
                <Route
                  path="/testimonial_set"
                  element={<TestimonialSetList />}
                />
                <Route
                  path="/testimonial_set_edit"
                  element={<TestimonialSetList />}
                />

                <Route path="/objection" element={<ObjectionList />} />
                <Route path="/objection_edit" element={<ObjectionList />} />
                <Route path="/objection_set" element={<ObjectionSetList />} />
                <Route
                  path="/objection_set_edit"
                  element={<ObjectionSetList />}
                />

                <Route path="/lpbuilder" element={<LPBuilder />} />

                <Route path="/faqs" element={<FaqList />} />
                <Route path="/faqs_edit" element={<FaqList />} />
                <Route path="/faq_cats" element={<FaqCatsList />} />
                <Route path="/faq_cats_edit" element={<FaqCatsList />} />
                <Route path="/faq_tags" element={<FaqTagsList />} />
                <Route path="/faq_tags_edit" element={<FaqTagsList />} />
                <Route path="/faq_synonyms" element={<FaqSynonymsList />} />
                <Route
                  path="/faq_synonyms_edit"
                  element={<FaqSynonymsList />}
                />
                <Route path="/faq_ques" element={<FaqQuesList />} />
                <Route path="/faq_ques_edit" element={<FaqQuesList />} />
                <Route path="/faq_ans" element={<FaqAnsList />} />
                <Route path="/faq_ans_edit" element={<FaqAnsList />} />

                <Route
                  path="/chat_trainings"
                  element={<FaqChattrainingList />}
                />
                <Route
                  path="/chat_trainings_edit"
                  element={<FaqChattrainingList />}
                />

                <Route path="/faq_suggested" element={<FaqSuggestedAdd />} />
                <Route
                  path="/faq_suggested_edit"
                  element={<FaqSuggestedEdit />}
                />

                <Route
                  path="/faq_suggested_v2"
                  element={<FaqSuggestedAdd_v2 />}
                />
                <Route
                  path="/faq_suggested_edit_v2"
                  element={<FaqSuggestedEdit_v2 />}
                />

                <Route path="/snippets" element={<SnippetsList />} />
                <Route path="/snippets_edit" element={<SnippetsList />} />

                <Route path="/chat_settings" element={<ChatPicAdd />} />

                <Route path="/lead-test-form" element={<NcmForm />} />
                <Route path="/gmail-api" element={<GmailApi />} />

                <Route path="/post" element={<PostList />} />
                <Route path="/post_edit" element={<PostList />} />
                <Route path="/post_cat" element={<PostCatList />} />
                <Route path="/post_cat_edit" element={<PostCatList />} />

                <Route path="/post_setting" element={<PostSettingList />} />
                <Route path="/post_setting_by_name" element={<PostByname />} />

                <Route path="/pages" element={<PagesList />} />
                <Route path="/page_edit" element={<PagesList />} />

                <Route path="/websites" element={<WebsitesList />} />
                <Route path="/website_edit" element={<WebsitesList />} />

                <Route path="/site" element={<SiteList />} />

                <Route path="/message" element={<MessageList />} />
                <Route path="/message_edit" element={<MessageList />} />

                <Route path="/quotes" element={<QuotesList />} />
                <Route path="/quote_edit" element={<QuotesList />} />

                <Route path="/showcases" element={<ShowcasesList />} />
                <Route path="/showcase_edit" element={<ShowcasesList />} />

                <Route path="/videos" element={<VideosList />} />
                <Route path="/videos_edit" element={<VideosList />} />

                <Route path="/contact_text" element={<ContactTextList />} />
                <Route
                  path="/contact_text_edit"
                  element={<ContactTextList />}
                />

                <Route path="/virtual_tours" element={<VirtualToursList />} />
                <Route
                  path="/virtual_tour_edit"
                  element={<VirtualToursList />}
                />

                <Route path="/offers" element={<OffersFormList />} />
                <Route path="/offer_edit" element={<OffersFormList />} />

                <Route path="/form_builder" element={<FormBuilderList />} />
                <Route path="/form_builder_edit" element={<FormBuilderList />} />

                <Route
                  path="/custom_form_addnew"
                  element={<CustomFormList />}
                />
                <Route path="/custom_form_edit" element={<CustomFormList />} />

                <Route path="/offer_groups" element={<OffersGroupsList />} />
                <Route
                  path="/offer_group_edit"
                  element={<OffersGroupsList />}
                />

                <Route path="/email_temps" element={<EmailTempsList />} />
                <Route path="/email_temp_edit" element={<EmailTempsList />} />

                <Route path="/email_groups" element={<EmailGroupsList />} />
                <Route path="/email_group_edit" element={<EmailGroupsList />} />

                <Route path="/email_filters" element={<EmailFilteringList />} />
                <Route path="/email_filters_edit" element={<EmailFilteringList />} />

                <Route path="/widget_image_setting" element={<WidgetImageSettingList />} />
                <Route path="/widget_image_setting_edit" element={<WidgetImageSettingList />} />

                <Route path="/heroes" element={<HeroesList />} />
                <Route path="/heroes_edit" element={<HeroesList />} />

                <Route
                  path="/clientgalleryimages"
                  element={<ClientGalleryImagesList />}
                />
                <Route
                  path="/clientgalleryimage_edit"
                  element={<ClientGalleryImagesList />}
                />

                <Route
                  path="/clientstoryboards"
                  element={<ClientStoryBoardsList />}
                />
                <Route
                  path="/clientstoryboard_edit"
                  element={<ClientStoryBoardsList />}
                />

                <Route
                  path="/followup_email_temps"
                  element={<FollowUpEmailTempsAdd />}
                />
                <Route
                  path="/followup_email_temp_edit"
                  element={<FollowUpEmailTempsEdit />}
                />

                <Route
                  path="/followup_email_groups"
                  element={<FollowUpEmailGroupsAdd />}
                />
                <Route
                  path="/followup_email_group_edit"
                  element={<FollowUpEmailGroupsEdit />}
                />

                <Route path="/media" element={<MediaList />} />
                <Route path="/media_edit" element={<MediaList />} />

                <Route path="/lpsessions" element={<LpsessionAdd />} />
                <Route path="/lpsession_view" element={<LpsessionEdit />} />

                <Route path="/assign_heroes" element={<AssignHeroes />} />
                <Route path="/assign_offers" element={<AssignOffers />} />
                <Route path="/assign_videos" element={<AssignVideos />} />
                <Route path="/assign_quotes" element={<AssignQuotes />} />
                <Route
                  path="/assign_clientvars"
                  element={<AssignClientVars />}
                />
                <Route path="/assign_varsets" element={<AssignVarSets />} />
                <Route
                  path="/assign_html_blocks"
                  element={<AssignHtmlBlocks />}
                />
                <Route
                  path="/assign_htmlblock_sets"
                  element={<AssignHtmlBlockSets />}
                />
                <Route
                  path="/assign_virtual_tours"
                  element={<AssignVirtualTours />}
                />
                <Route path="/assign_showcases" element={<AssignShowcases />} />
                <Route path="/assign_gallery" element={<AssignGallery />} />
                <Route
                  path="/assign_storyboard"
                  element={<AssignStoryboard />}
                />
                <Route
                  path="/assign_heading_images"
                  element={<AssignHeadingImages />}
                />
                <Route
                  path="/assign_offer_groups"
                  element={<AssignOfferGroups />}
                />

                <Route path="/test" element={<TestAdd />} />
                <Route path="/test_edit" element={<TestEdit />} />

                <Route element={<NotFound />} />
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </CPHandlerProvider>
      );
    } else {
      return (
        <CPHandlerProvider>
          <BrowserRouter>
            <React.Suspense
              fallback={
                <div className="lazyLoader">
                  <span></span>
                </div>
              }
            >
              <Routes>
                <Route exact path="/" element={<RecordList />} />
                <Route exact path="/dashboard" element={<RecordList />} />
                <Route path="/leads" element={<LeadAddNew />} />
                <Route path="/lead_addnew" element={<LeadAddNew />} />
                <Route path="/lead_view" element={<LeadView />} />
                <Route path="/lead_edit" element={<LeadEdit />} />
                <Route path="/incoming" element={<LeadIncoming />} />
                <Route path="/lead_board" element={<LeadBoard />} />
                <Route path="/lead_client_dash" element={<LeadClientDash />} />
                <Route element={<NotFound />} />
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </CPHandlerProvider>
      );
    }
  } else if (gauth === true) {
    return (
      <BrowserRouter>
        <React.Suspense
          fallback={
            <div className="lazyLoader">
              <span></span>
            </div>
          }
        >
          <Routes>
            <Route path="/gauth-callback" element={<GoogleToken />} />
            <Route path="/lead_view" element={<LeadView />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <React.Suspense
          fallback={
            <div className="lazyLoader">
              <span></span>
            </div>
          }
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/gauth-callback" element={<GoogleToken />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    );
  }
};

export default App;
